import { Banner } from "ds/Banner";
import { BannerStatus } from "ds/Banner/src/types";
import { ColorNeutralBlack, Space300, SpaceNone } from "ds/Core";
import { MainNavigationProps } from "ds/MainNavigation";
import { NavigationHeaderProps } from "ds/NavigationHeader/src/types";
import { down } from "ds/theme";
import { TypographyType } from "ds/types";
import { Box, BoxExtendedProps } from "grommet";
import { PadType } from "grommet/es6/utils";
import linkifyHtml from "linkify-html";
import { LIVEVIEW_REQUEST_REACT_OPEN_NAV } from "liveview-react-constants";
import React, { createContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useRouteMatch } from "react-router-dom";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { PastDueInvoiceModal } from "components/PastDueInvoiceModal/PastDueInvoiceModal";
import { TInboxGroupingSummary } from "graphql/inbox";
import { TOrganisationDetails } from "graphql/organisation";
import { TInitialNumber } from "graphqlQueries";
import { useGlobalContext, useNavigation } from "hooks";
import { usePaymentContext } from "hooks/usePaymentContext";
import { HEADER_HEIGHT } from "pages/NumberDetail/pages/Inbox/styles";
import { getNumberByUuid } from "selectors";
import { isSandbox } from "selectors/number";
import { safelyParseHtml } from "utils/html";
import { FeedbackFormLink, turnLearnLink } from "utils/links";
import { getStorageSwitch } from "utils/localStorage";
import { NAVIGATION_SIDEBAR_COLLAPSED_WIDTH, NAVIGATION_SIDEBAR_WIDTH } from "utils/styles";

import { NavigationSidebar } from "./NavigationSidebar";
import { PAYMENT_BANNER_HEIGHT, PaymentBanner } from "./PaymentBanner";
import { RouteWithNavigationProps } from "./RouteWithNavigation";
import { StyledNavigationHeader, StyledText } from "./styles";
import { useOrganisationMenu } from "./useOrganisationMenu";
import { useRouteNavigationProps } from "./useRouteNavigationProps";

export const LayoutContext = createContext<{ offset: `${number}px` }>({ offset: "0px" });

interface LayoutProps extends NavigationHeaderProps {
  children?:
    | (React.ReactElement<RouteWithNavigationProps> | React.ReactNode)[]
    | React.ReactElement<RouteWithNavigationProps>
    | React.ReactNode;
  hideNavigationHeader?: boolean;
  containerProps?: Omit<BoxExtendedProps, "children">;
  notifications?: {
    message: React.ReactNode;
    page?: string;
    status?: `${BannerStatus}`;
    fontSize?: TypographyType["fontSize"];
  }[];
  pad?: PadType;
  notificationSize?: number;
  backTo?: string;
  organisation?: TOrganisationDetails;
  number?: TInitialNumber;
  yourInboxStats?: TInboxGroupingSummary | null;
  showSidebar?: boolean;
  navigationHeaderProps?: NavigationHeaderProps;
  mainNavigationProps?: Partial<MainNavigationProps>;
  collapsedSidebar?: boolean;
  offset?: `${number}px`;
  isIframe?: boolean;
  hideHelp?: boolean;
  hideMenu?: boolean;
}

export const Layout = ({
  children,
  containerProps,
  hideNavigationHeader,
  notifications,
  pad = Space300,
  notificationSize,
  backTo,
  organisation,
  number,
  yourInboxStats,
  showSidebar,
  navigationHeaderProps,
  mainNavigationProps,
  collapsedSidebar,
  offset = SpaceNone,
  isIframe = false,
  hideHelp = false,
  hideMenu = false,
}: LayoutProps) => {
  const { setVisibleMenu, UNSAFE_organisationUuid, numberUuid, isAdmin } = useGlobalContext();
  const { state: paymentState, status, exemptedNumber } = usePaymentContext();
  const isMobile = useBreakpoint(down("lg"));
  const routeNavigation = useRouteNavigationProps(children);
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { navigateTo } = useNavigation();
  const { t } = useTranslation();

  const currentNumber = getNumberByUuid(organisation, numberUuid);
  const sandbox = isSandbox(currentNumber);

  const isPath = (numberPath: string) =>
    !!matchPath(pathname, {
      path: `${path}${numberPath}`,
      exact: true,
    });

  const onOpenNavs = () => {
    setVisibleMenu(true);
  };

  const { organisationMenuProps } = useOrganisationMenu();

  const [notificationsContainerHeight, setNotificationsContainerHeight] = useState(0);
  const notificationsContainerRef = useRef<HTMLDivElement>(null);

  // context(alexandrchebotar, 2023-11-30): need to run it after every render to check taken by notifications space. This is a simple workaround without using ResizeObserver.
  //  But height will not be updated directly after resizing page, only after re-rendering component. It mostly satisfied our needs: set correct offset after the initial render.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const height = notificationsContainerRef.current?.getBoundingClientRect().height;

    if (height && height !== notificationsContainerHeight) {
      setNotificationsContainerHeight(height);
    }
  });

  const isVisiblePaymentBanner =
    !getStorageSwitch(`hidePaymentBanner_${numberUuid}_${status?.status}`) &&
    isAdmin &&
    paymentState &&
    !sandbox &&
    !exemptedNumber;
  const outerOffset = parseInt(offset) + (isVisiblePaymentBanner ? PAYMENT_BANNER_HEIGHT : 0);

  const localOffset = outerOffset + notificationsContainerHeight + (hideNavigationHeader ? 0 : parseInt(HEADER_HEIGHT));

  // context(Aratramba, 2024-03-04) open navigation from inside liveview iframe
  useEffect(() => {
    const onMessage = (e: MessageEvent) => {
      if (e.data.type === LIVEVIEW_REQUEST_REACT_OPEN_NAV) {
        setVisibleMenu(true);
      }
    };

    window.addEventListener("message", onMessage);
    return () => window.removeEventListener("message", onMessage);
  }, [setVisibleMenu]);

  return (
    <LayoutContext.Provider value={{ offset: `${localOffset}px` }}>
      <PaymentBanner sandbox={sandbox} />
      <PastDueInvoiceModal />
      <Box margin={{ top: `${outerOffset}px` }} flex>
        {showSidebar && organisation && number && (
          <NavigationSidebar
            yourInboxStats={yourInboxStats}
            organisation={organisation}
            number={number}
            organisationMenuProps={organisationMenuProps}
            collapsedSidebar={collapsedSidebar}
            offset={`${outerOffset}px`}
            {...mainNavigationProps}
            {...routeNavigation.mainNavigationProps}
          />
        )}
        <Box
          margin={{
            left:
              isMobile || !showSidebar
                ? "0px"
                : collapsedSidebar
                  ? NAVIGATION_SIDEBAR_COLLAPSED_WIDTH
                  : NAVIGATION_SIDEBAR_WIDTH,
          }}
          width={
            isMobile || !showSidebar
              ? "100%"
              : `calc(100vw - ${collapsedSidebar ? NAVIGATION_SIDEBAR_COLLAPSED_WIDTH : NAVIGATION_SIDEBAR_WIDTH})`
          }
          height="100%"
        >
          {!hideNavigationHeader && UNSAFE_organisationUuid && (
            <StyledNavigationHeader
              back={backTo ? { onClick: navigateTo(backTo) } : undefined}
              navs={{ onClick: onOpenNavs, "aria-label": "navs-trigger" }}
              menu={showSidebar || hideMenu ? undefined : organisationMenuProps}
              help={showSidebar || hideHelp ? undefined : { url: turnLearnLink, className: "guide-4" }}
              feedback={
                showSidebar
                  ? undefined
                  : {
                      url: FeedbackFormLink,
                      target: "_blank",
                      tooltip: { content: t("menu.feedback") },
                    }
              }
              width={
                isMobile || !showSidebar
                  ? "100%"
                  : `calc(100vw - ${collapsedSidebar ? NAVIGATION_SIDEBAR_COLLAPSED_WIDTH : NAVIGATION_SIDEBAR_WIDTH})`
              }
              {...navigationHeaderProps}
              {...routeNavigation.navigationHeaderProps}
            />
          )}
          <Box height="100%" margin={{ top: hideNavigationHeader ? "0px" : "64px" }}>
            <Box ref={notificationsContainerRef}>
              {notifications?.map(
                (notification, index) =>
                  (!notification.page || isPath(notification.page)) && (
                    <Banner
                      width={isMobile ? "100%" : `calc(100% - ${notificationSize}px)`}
                      status={notification.status}
                      minHeight="38px"
                      key={index}
                    >
                      <StyledText textAlign="center" fontSize={notification.fontSize || "base"}>
                        {typeof notification.message === "string"
                          ? safelyParseHtml(
                              linkifyHtml(notification.message, {
                                target: "_blank",
                                attributes: {
                                  style: `color: ${ColorNeutralBlack};`,
                                },
                              })
                            )
                          : notification.message}
                      </StyledText>
                    </Banner>
                  )
              )}
            </Box>
            <Box
              pad={isIframe ? SpaceNone : pad}
              {...containerProps}
              style={
                isIframe
                  ? {
                      flexGrow: 1,
                      height: `calc(calc(var(--svh, 1vh) * 100) - ${notificationsContainerHeight}px${isVisiblePaymentBanner ? ` - ${PAYMENT_BANNER_HEIGHT}px` : ""})`,
                    }
                  : undefined
              }
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </LayoutContext.Provider>
  );
};
