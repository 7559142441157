import { FlagDefinitions, flagg } from "flagg/dist/core";
import { inMemoryStore, localStore } from "flagg/dist/store";

import { Maybe, ValueOf } from "types";

export const FeatureFlag = {
  BUILD_20: "build_20",
  DISPLAY_TRANSCRIPTIONS: "display_transcriptions",
  EMBEDDED_SIGNUP_V2: "embedded_signup_v2",
  HANDOVER: "handover",
  INBOX: "inbox",
  LIVEVIEW_INBOX: "liveview_inbox",
  LLM: "llm",
  MFA: "mfa",
  OPERATOR_METRICS: "operator_metrics",
  TRANSLATION: "translation",
  TRIGGER: "trigger",
  VARIABLES: "variables",
  MULTIPLE_ASSISTANTS: "multiple_assistants",
} as const;

export type TFeatureFlag = ValueOf<typeof FeatureFlag>;

const isDevOrQA = /localhost|dev-backend.turn.systems|pr.turn.io|qa.app.turn.io|qa.whatsapp.turn.io/.test(
  window.location.host
);

export const featureFlags = flagg({
  store: isDevOrQA ? localStore() : inMemoryStore(),
  definitions: {
    ...Object.values(FeatureFlag).reduce(
      (definitions: FlagDefinitions, feature: string) => ({
        ...definitions,
        [feature]: { default: false },
      }),
      {}
    ),
  },
});

export const initFeatureFlags = (enabledFeatures: Maybe<(string | null)[]>) => {
  // context(patricia-montoya, 2023-11-27): set Inbox FF enabled while tests are fixed
  (enabledFeatures ?? []).forEach((feature) => {
    const localValue = window.localStorage.getItem(`ff_${feature}`);

    // context(alexandrchebotar, 2023-10-17): set feature flag only if it's prod or there is no setting for that feature in localStorage
    if (feature && (!isDevOrQA || !localValue)) {
      featureFlags.set(feature, true);
    }
  });

  if (!isDevOrQA) {
    featureFlags.freezeAll();
  }
};

export const isEnabledFeature = (feature: TFeatureFlag) => !!featureFlags.get(feature);
