import gql from "graphql-tag";

import { ORGANISATION_ACCOUNT_DETAILS_FRAGMENT } from "./fragments";

export const CREATE_ACCOUNT = gql`
  mutation createAccount($email: String!, $organisationUuid: ID!) {
    createAccount(email: $email, organisationUuid: $organisationUuid) {
      ...OrganisationAccountDetails
    }
  }

  ${ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.query}
`;

export const CREATE_ACCOUNTS = gql`
  mutation createAccounts($organisationUuid: ID!, $accountInputs: [AccountInput]) {
    createAccounts(organisationUuid: $organisationUuid, accountInputs: $accountInputs) {
      ...OrganisationAccountDetails
    }
  }

  ${ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.query}
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount($organisationUuid: ID!, $accountUuid: ID!) {
    deleteAccount(organisationUuid: $organisationUuid, accountUuid: $accountUuid) {
      ...OrganisationAccountDetails
    }
  }

  ${ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.query}
`;

export const UPDATE_ORGANISATION_ACCOUNT = gql`
  mutation updateOrganisationAccount($organisationUuid: ID!, $accountUuid: ID!, $isAdmin: Boolean!, $role: RoleType) {
    updateOrganisationAccount(
      organisationUuid: $organisationUuid
      accountUuid: $accountUuid
      isAdmin: $isAdmin
      role: $role
    ) {
      ...OrganisationAccountDetails
    }
  }

  ${ORGANISATION_ACCOUNT_DETAILS_FRAGMENT.query}
`;
